const pages = [{
  //   title: 'Home',
  //   path: '/'
  // },
  // {
    title: 'About',
    path: 'about'
  },
  {
    title: 'Code',
    path: 'code'
  },
  {
    title: 'Links',
    path: 'links'
  },
];

export default pages;